import { db } from './firebase';
import { getDatabase, ref, get, query, orderByKey, startAt, endAt, orderByChild, startAfter, endBefore, limitToLast, set, remove } from "firebase/database";

export class FbStory {
	static async get({ timeStart, timeEnd, num }) {
		const path = 'story';
		const _ref = ref(db, path);
		let _query = query(_ref, orderByChild('time'));
		if (timeStart) { _query = query(_query, startAfter(timeStart)); }
		if (timeEnd) { _query = query(_query, endBefore(timeEnd)); }
		//_query = query(_query, startAfter("20230805143003416"));
		//_query = query(_query, endBefore("20230805143003416")); 
		if (num) { _query = query(_query, limitToLast(num)); }
	  
		console.log('firebase Get getStory !!!!!!!!')
		console.log('start:' + timeStart + ' end:' + timeEnd)
	  const snap = await get(_query);
	  if (snap.exists()) {
		const data = [];
		snap.forEach(childSnapshot => {
		  const mdStory = childSnapshot.val();
		  data.push(mdStory);
		});

		data.sort((a, b) => {
			return (b.time || '').localeCompare(a.time || '');
		});
		console.log('max:' + data[0].time + ' min:' + data[data.length - 1].time)
		return data;
	  } else {
		console.log("No data available");
	  }
	}
  
	static async getStoryData(idStory) {
	  const path = `story/${idStory}`;
	  const _ref = ref(db, path);
	  const snap = await get(_ref);
	  if (snap.exists()) {
		const data = snap.val();
		return data;
	  } else {
		console.log("No data available");
	  }
	}
  
	static setRecom({ uid, idStory }) {
	  const path = `user/${uid}/storyRecom`;
	  const data = idStory;
	  set(ref(db, path), data);
	}
  
	static async getRecom({ uid }) {
	  const path = `user/${uid}/storyRecom`;
	  const _ref = ref(db, path);
	  const snap = await get(_ref);
	  if (snap.exists()) {
		const data = snap.val();
		return data;
	  } else {
		throw new Error("No data available");
	  }
	}
  
	static onLike(mdStory, mid) {
	  const id = mdStory.idStory;
	  const token = mdStory.token;
	  const path = `story/${id}/like/${mid}`;
	  const data = token;
	  set(ref(db, path), data);
	}
  
	static offLike(idStory, mid) {
	  const path = `story/${idStory}/like/${mid}`;
	  remove(ref(db, path));
	}
  
	static onShow(id) {
	  const path = `story/${id}/numShow`;
	  //set(ref(db, path), ServerValue.increment(1));
	}
  
	static async getStorySawUser({ uid }) {
	  const path = `storySawUser/${uid}`;
	  const _ref = ref(db, path);
	  const snap = await get(_ref);
	  if (snap.exists()) {
		const data = snap.val();
		return data;
	  } else {
		throw new Error("No data available");
	  }
  
	}
  
	static async setStorySawMy({ mapScoreStory, mid }) {
	  const path = `storySawUser/${mid}`;
	  const data = mapScoreStory;
	  set(ref(db, path), data);
	}
  }