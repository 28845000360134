import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCavtol-7PpbJzbrk2XQsf2pLd3sjVh6tY",
  authDomain: "peel-ubf.firebaseapp.com",
  databaseURL: "https://peel-ubf-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "peel-ubf",
  storageBucket: "peel-ubf.appspot.com",
  messagingSenderId: "126147493411",
  appId: "1:126147493411:web:5b50dcaa461ef2bbddfb96"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth()

export const db = getDatabase()
  
const functions = getFunctions();
export const getCountry = httpsCallable(functions, 'getCountry');
