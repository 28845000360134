import React, { useState, useEffect ,useRef} from 'react';
import {ObStory } from './ObStory';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MdFavorite, } from 'react-icons/md';
import {BiExpand,BiSolidBarChartAlt2} from 'react-icons/bi'
import {FaPause,FaPlay} from 'react-icons/fa'
import {IoIosPause} from 'react-icons/io'
import {ImVolumeMute2,ImVolumeMedium, ImVolumeMute,} from 'react-icons/im'
import {BsVolumeUpFill, BsVolumeMuteFill} from 'react-icons/bs' 
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { FbStory } from './FbStory';
import { Helmet } from 'react-helmet';
import AdmaxSwitch from './admax';
import { auth, getCountry } from './firebase';
import { signInAnonymously , onAuthStateChanged } from "firebase/auth";
import VwAd from "./VwAd"
import axios from 'axios';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './LaStory.css'


// import required modules
import { Pagination } from 'swiper/modules';
import { endBefore, startAfter } from 'firebase/database';

const useTapDetection = (onSingleTap, onDoubleTap, delay = 300) => {
  const [tapCount, setTapCount] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if (tapCount === 1) {
      // シングルタップの場合
      timerRef.current = setTimeout(() => {
        onSingleTap();
        setTapCount(0);
      }, delay);
    } else if (tapCount === 2) {
      // ダブルタップの場合
      clearTimeout(timerRef.current);
      onDoubleTap();
      setTapCount(0);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [tapCount, onSingleTap, onDoubleTap, delay]);

  // タップイベントのハンドラ
  const handleTap = () => {
    setTapCount((prev) => prev + 1);
  };

  return handleTap;
};

const VwStoryList = () => {

  const indexSignLoad = 3;
  const indexAd = 4;
  const numLoadCashe = 5;
  const numLoadStory = 5;
  
  const loadingStoryGet = useRef(false)
  const touchStartY = useRef(0);
  const totalScrollY = useRef(0);
  const refIndexActive = useRef(0);
  const scoreNow = useRef(0);

  const historyTimeStory = useRef([]);
  const mapScore = useRef({});
  const sliderRef = useRef(null);
  const scrollViewRef = useRef(null);
  const vwScrollStoryRef = useRef(null);
  const refListStoryLeft = useRef(null);
  const refListStoryRight = useRef(null);
  const refListStoryHeader = useRef(null);
  
  const [listStory, setListStory] = useState([]);
  const [listPlay, setListPlay] = useState([]);
  const [listSound, setListSound] = useState([]);
  
  const [vwScrollStoryHeight, setVwScrollStoryHeight] = useState(2000);
  const [expandedVideoIndex, setExpandedVideoIndex] = useState(-1);
  const listRefVideo = listStory.map(() => React.createRef());
  
  const [showAd,setShowAD] = useState(false);
  const [permAge ,setPermAge] = useState(false);
  const [showIconPopLike, setShowIconPopLike] = useState(false);

  useEffect(() => {
    console.log('useEffect')
    checkPermAge();
    initAsync();
    window.addEventListener('scroll', onScroll, { passive: false });
    // コンポーネントがアンマウントされる際にイベントリスナーをクリーンアップ
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const initAsync = async() => {
    await signIn();
    fetchStory(0)
  }

  const signIn = async() => {
    if(auth.currentUser != null)return;
    console.log('signIn anonymously')
    await signInAnonymously(auth).then(() => {
      console.log(' Signed in !!!!!!!!!!!!!!!!!')
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('auth erro code : ' + errorCode + ', msg : ' + errorMessage)
    });
  }

  const feathCountry = async () => {
    try {
      const response = await fetch('https://us-central1-peel-ubf.cloudfunctions.net/getCountry', {
        method: 'GET'
      });
  
      if (!response.ok) {
        throw new Error('Fetch failed');
      }
  
      const ipAddress = await response.text();
      console.log('IP address:', ipAddress);
  
      const countryResponse = await axios.get(`https://ipinfo.io/${ipAddress}/json`);
      const country = countryResponse.data.country; // 国を取得
      console.log('Country: ' + country);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const checkPermAge = () => {
    const bool = localStorage.getItem('permAge');
    setPermAge(bool);
  }

  const onPermAge = () => {
    localStorage.setItem('permAge', true);
    setPermAge(true)
  }

  const getStoryOfTime = async() => {
		console.log('get Story'); // For debugging purposes, display a message
		let list = []; // List to store retrieved stories
		if (historyTimeStory.current.length === 0) {
			const data = await FbStory.get({ num: numLoadStory });
			if (data != null) {
				list = data;
        historyTimeStory.current.push({
          max: list[0].time,
          min: list[list.length - 1].time,
        });
			} else {
				return [];
			}
		}else{
			let i = 0;
			while (list.length < numLoadStory && i <= historyTimeStory.current.length) {
				let timeStart;
				let timeEnd;

				if (i === 0) {
					timeStart = historyTimeStory.current[i].max;
				} else {
					timeEnd = historyTimeStory.current[i - 1].min;
					if (historyTimeStory.current.length > i) {
						timeStart = historyTimeStory.current[i].max;
					}
				}

				const data = await FbStory.get({
					num: numLoadStory,
					timeStart: timeStart,
					timeEnd: timeEnd,
				});

				if (data == null) {
					i += 1;
					continue;
				} else {
					list = [...list,...data];
				}
				if (list.length < numLoadStory) {
					  if (i === 0) {
							historyTimeStory.current[i].max = list[0].time;
						}else if(i < historyTimeStory.current.length - 1){
							historyTimeStory.current[i-1].min = historyTimeStory.current[i].min;
							historyTimeStory.current.splice(i, 1);
						} else {
							historyTimeStory.current[i-1].min = list[list.length - 1].time;
						}
					  i += 1
				} else {
					if (i === 0) {
						historyTimeStory.current.splice(0,0,{
								max: list[0].time,
								min: list[list.length - 1].time,
            })
					} else {		
						historyTimeStory.current[i-1].min = list[list.length - 1].time;
					}
				}
			}
		}

		const listVideo = list.filter(item => item.hasOwnProperty('urlVideo') && item.urlVideo !== null);
		console.log('getStoryOfTime timeMax : ' + listVideo[0]?.time)
		return listVideo;
	}

  var fetchStory = async (index) => {
    if(auth.currentUser == null) return;
    if((listStory.length - index) > indexSignLoad)return;
    
    if (!loadingStoryGet.current) {
      loadingStoryGet.current = true; // Set the loa
      var list = await getStoryOfTime();
      list = insertAd(list);
      setListStory((stories) => [...stories, ...list]);
      loadingStoryGet.current = false;
    }
  };

  const insertAd = (listM) => {
    const list = [...listM]
    const listIndexAd = []
    for(let i = 0;i <  list.length ;i++){
      const indexNew = i + listPlay.length;
      if(indexNew != 0 && indexNew % 4 === 0){
      //if(indexNew % 4 === 0){
        listIndexAd.push(i);
      }
    }

    var count = 0;
    for(let j of listIndexAd){
      list.splice(j + count,0,{
        idStory : 'ad_' + (listStory.length + j)
      })
      count += 1;
    }
    return list
  }

  const onScore = (index) => {
    const idStory = listStory[index].idStory;
    if(idStory.substring(0,3) != 'ad_' && !(idStory in mapScore.current)){
      
    }
  }

  function handleSlideChange(swiper) {
    // スライドが変更されたときの処理をここに書きます
    const index = swiper.activeIndex

    if(index != 0 && index % indexAd ===  0){
      setShowAD(true);
    }else{
      setShowAD(false);
    }

    onPlay(index)
    //戻った時
    if(refIndexActive > index){
      onPause(index + 1);
    }
    if(index != 0)onPause(index-1);

    refIndexActive.current = index;
    console.log(listStory[index])
    fetchStory(index)
    onScore(index)
  }
  
  function handleSwiper(swiper) {
    // Swiper インスタンスが取得できたときの処理をここに書きます
    console.log('Swiper インスタンスが取得されました。');
    const index = swiper.activeIndex
    console.log(listStory[index])
  }

  const onScroll = (event) => {
    window.scrollTo({
      top: window.scrollY,
      behavior: 'auto', // 'auto'を指定して即座に停止する
    });
  }

  const onTouchStart = (event) => {
    touchStartY.current = event.touches[0].clientY;
  }

  const onTouchMove = (event) => {
    const touchCurrentY = event.touches[0].clientY;
    const scrollY = touchCurrentY - touchStartY.current;
    totalScrollY.current += scrollY;
    touchStartY.current = touchCurrentY;
  };

  const onTapSingle = () => {
    console.log('onTap ' + refIndexActive.current)
    //onMute(refIndexActive.current);
  }

  const onTapDouble = () => {
    console.log('onTapDouble')
    setShowIconPopLike(true);

    // 1秒後にアイコンを非表示にするタイマーを設定
    setTimeout(() => {
      setShowIconPopLike(false);
    }, 1000);
  }

  const onTap = useTapDetection(onTapSingle, onTapDouble);

  const onTouchEnd = () => {
    if(Math.abs(totalScrollY.current) < 10){
      onTap();
    }else{
      onSwipe(totalScrollY.current)
    }
    totalScrollY.current = 0;
  };


  const onSwipe = (value) => {
    if(totalScrollY.current > 0){
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
      refListStoryHeader.current.swiper.slidePrev();
      refListStoryRight.current.swiper.slidePrev();
      refListStoryLeft.current.swiper.slidePrev();


    }else if(totalScrollY.current < 0){
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
      refListStoryHeader.current.swiper.slideNext();
      refListStoryRight.current.swiper.slideNext();
      refListStoryLeft.current.swiper.slideNext();

      
      const newHeight = vwScrollStoryHeight - totalScrollY.current;
      setVwScrollStoryHeight(newHeight);
    }
  }

  const onExpand = (index) => {
    if (index === expandedVideoIndex) {
      // 同じビデオがすでに展開されている場合、フルスクリーン表示を解除
      exitFullscreen();
      setExpandedVideoIndex(-1);
    } else {
      // 新しいビデオを展開'=
      
      requestFullscreen(listRefVideo[index].current);
      setExpandedVideoIndex(index);
    }
  };

  const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen();
    } else if (element.webkitEnterFullScreen) { // iOS Safari
      element.webkitEnterFullScreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    } else if (document.webkitExitFullscreen) { // iOS Safari
      document.webkitExitFullscreen();
    }
  };

  const onMute = (index) => {
    if (listSound.includes(index)) {
      setListSound(listSound.filter(i => i !== index));
    } else {
      setListSound([...listSound, index]);
    }
  };

  const onPlayTogle = (index) => {
    const elm = listRefVideo[index].current
    if (listPlay.includes(index)){
      onPlay(index)
    } else {
      onPause(index)
    }
  }

  const onPlay = (index) => {
    if(index != 0 && index % indexAd ===  0)return;
    const elm = listRefVideo[index].current
    console.log('onPlay ' + index)
      elm.play();
      setListPlay(listPlay.filter(i=> i !== index))
  }

  const onPause = (index) => {
    if(index != 0 && index % indexAd ===  0)return;
    const elm = listRefVideo[index].current
    console.log('onPaunse ' + index)
      elm.pause();
      setListPlay([...listPlay, index]);
  }

  return (
    <div>
      <div id='vwListStory'>
        <Swiper
          ref={sliderRef}
          direction="vertical"
          slidesPerView={1}
          mousewheel={true}
          style={{ height: '100vh', width:'100vw'}}
          onSlideChange={handleSlideChange}
          onSwiper={handleSwiper}
          allowTouchMove={false}
        >
          {listStory.map((story, index) => (
            <SwiperSlide key={story.idStory}>
              {story.idStory.substring(0,3) === 'ad_' ? <a/>
                : <video
                  ref={listRefVideo[index]}
                  autoPlay
                  loop
                  muted={!listSound.includes(index)}
                  playsInline
                  style={{ height: '100vh', width: '100%'}}
                  src={story.urlVideoShort}
                >
                </video>
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={`iconPopupLike ${showIconPopLike ? 'show' : ''}`}>
        {showIconPopLike && <MdFavorite size={100} color={'rgb( 255, 0, 100 '} opacity={'0.8'}/>}
      </div>  
      <div id='vwWrapStory' onScroll={onScroll}>
        <div 
          id='vwScrollStory' 
          ref={vwScrollStoryRef} 
          onTouchStart={onTouchStart} 
          onTouchEnd={onTouchEnd} 
          onTouchMove={onTouchMove}
          onClick={() => onMute(refIndexActive.current)}
          style={{ height: `${vwScrollStoryHeight}px` }}
        />
      </div>

      <Swiper
        ref={refListStoryHeader}
        direction="vertical"
        slidesPerView={1}
        mousewheel={true}
        style={{height:'40px', width:'100vw',position:'fixed',top:'8px'}}
        noSwiping={true}
        allowTouchMove={false}
      >
        {listStory.map((story, index) => (
          <SwiperSlide key={'menuHeader_' + story.idStory }>
            {story.idStory.substring(0,3) === 'ad_' ? <a/> :
            <div className='rowStoryMenuHeader' style={{ display: 'flex', justifyContent: 'space-between'}} >
              <p className='rowStoryMenuHeaderCol rowStoryMenuIconChart'><BiSolidBarChartAlt2 size={24} color={'white'}/></p>
              <p className='rowStoryMenuHeaderCol rowStoryMenuNumChart'>20</p>
              {/* {story.numShow}</div> */}
              <p className='rowStoryMenuHeaderCol btnStoryExpand' onClick={() => onExpand(index)}><BiExpand size={40} color={'white'}/></p>
            </div>}
          </SwiperSlide>
        ))}
      </Swiper>
      <div id='swiperStoryMenuLeft'>
        <Swiper
          ref={refListStoryLeft}
          direction="vertical"
          slidesPerView={1}
          mousewheel={true}
          style={{ height: '0vh',width : '0px',position: 'fixed',bottom:'0',left : '0'}}
          noSwiping={true}
          allowTouchMove={false}
        >
          {listStory.map((story, index) => (
            <SwiperSlide key={'menuLeft_' + story.idStory }>
              {story.idStory.substring(0,3) === 'ad_' ? <a/> :
              <div className='rowStoryMenuPlay'>
                <p className='rowStoryMenuIcon'>\
                  {listPlay.includes(index)
                    ? <FaPlay onClick={() => onPlayTogle(index)} size={40} color={'white'}/>
                    : <FaPause onClick={() => onPlayTogle(index)} size={40} color={'white'}/>
                  }
                </p>
              </div>}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
        <Swiper
          ref={refListStoryRight}
          direction="vertical"
          slidesPerView={1}
          mousewheel={true}
          style={{height:'50vh', width:'40px',position:'fixed',right:'0',bottom:'0',marginRight:'8px'}}
          noSwiping={true}
          allowTouchMove={false}
        >
          {listStory.map((story, index) => (
            <SwiperSlide key={'menuRight_' + story.idStory }>
              {story.idStory.substring(0,3) === 'ad_' ? <a/> :
              <div className='swiperStoryMenuRight'>
                <div className='btnStoryLike'>
                  <p className='btnStoryLikeIcon'><MdFavorite size={40} color={'white'}/></p>
                  <p className='btnStoryLikeNum'>{story.like == undefined ? 0 : Object.keys(story.like).length}</p>
                </div>
                <div className='btnStoryMute'>
                  <p className='rowStoryMenuIcon'>
                    {listSound.includes(index)
                      ? <ImVolumeMedium onClick={() => onMute(index)} size={40} color={'white'}/>
                      : <ImVolumeMute2 onClick={() => onMute(index)} size={40} color={'white'} opacity={'0.5'}/>
                    }
                  </p>
                </div>
              </div>}
            </SwiperSlide>
          ))}
        </Swiper>
        {!showAd ? <a/> : <VwAd/>}
        {!permAge ? 
        <div id='ageVerif'>
          <div><div/></div>
          <span>
            <h1 >あなたは18歳以上ですか？</h1>
            <p>18歳未満の方はアクセスできません</p>

            <button onClick={onPermAge}>
              18以上です
            </button>
            
          </span>
        </div>:<a/>}
    </div>
  );
};

export default VwStoryList;
