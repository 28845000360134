
import React, { useState, useEffect ,useRef} from 'react';
const VwAd = () => {
	const adUrl = getRandomElement(listAd)

	return (
		<div style={{height:'auto', width:'100vw',position:'fixed',top:'10vh'}}>
			<iframe src={adUrl} frameBorder="0" border="0" scroll="no" scrolling="no" width="300px" height="250px" style={{border: '0px', overflow: 'hidden !important'}}></iframe>
		</div>
	)
}

export default VwAd

function getRandomElement(list) {
	return list.sort(() => Math.random() - 0.5)[0];
}
  

const listAd = [
	'https://static.javhd.com/h5/files/252/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74891.0.0.0%2F',
	"https://static.javhd.com/h5/files/5572/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74890.0.0.0%2F",
	"https://static.javhd.com/h5/files/440/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74889.0.0.0%2F",
	"https://static.javhd.com/h5/files/718/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74888.0.0.0%2F",
	"https://static.javhd.com/h5/files/2505/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74887.0.0.0%2F",
	"https://static.javhd.com/h5/files/5565/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74886.0.0.0%2F",
	"https://static.javhd.com/h5/files/5140/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74874.0.0.0%2F",
	"https://static.javhd.com/h5/files/5139/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74873.0.0.0%2F",
	"https://static.javhd.com/h5/files/5138/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74872.0.0.0%2F",
	"https://static.javhd.com/h5/files/5137/300x250.html?targetUrl=https%3A%2F%2Fenter.javhd.com%2Ftrack%2F36050.2.2.2.0.74870.0.0.0%2F",
	
]

